import { Fragment, useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { CancelOutlined, CheckCircle } from "@mui/icons-material";

import Error from "../Error/Error";
import { useAxios } from "../../hooks";
import { LoadingButton } from "../../components";
import EventBookingSuccess from "../Success/EventBookingSuccess";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  padding-top: 30px;
  border-radius: 5px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

function Consent() {
  const axiosDigipass = useAxios({
    baseURL: "digipass",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let bookingID = queryParams.get("booking_id");
  let visitorId = queryParams.get("visitorId");

  const localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return item.includes(visitorId);
  });

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [checkContactDetails, setCheckContactDetails] = useState(false);
  const [checkFaceData, setCheckFaceData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [eventDetail, setEventDetail] = useState({});
  const [displayMessage, setDisplayMessage] = useState("");
  const [redirectURL, setRedirectURL] = useState("");

  function handleSubmit() {
    let data = [];

    if (checkFaceData) {
      if (!data.includes("face")) {
        data.push("face");
      }
    } else {
      const faceIndex = data.indexOf("face");
      if (faceIndex !== -1) {
        data.splice(faceIndex, 1);
      }
    }

    if (checkContactDetails) {
      if (!data.includes("contact-details")) {
        data.push("contact-details");
      }
    } else {
      const contactIndex = data.indexOf("contact-details");
      if (contactIndex !== -1) {
        data.splice(contactIndex, 1);
      }
    }

    setLoading(true);
    axiosDigipass({
      url: "event/visitor/consent",
      method: "POST",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      data: {
        booking_id: bookingID,
        face_data: localStorageData[digipassDataKey[0]],
        consent_for: data,
      },
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setOpenSuccess(response.status);
        setDisplayMessage(response.message.displayMessage);
        setStatus(response.status);
      } else {
        setOpen(!response.status);
        setDisplayMessage(response.displayMessage);
        setRedirectURL(response.data.redirect_url);
      }

      setLoading(false);
    });
  }

  useEffect(() => {
    axiosDigipass({
      url: "event/booking/event-details/" + bookingID,
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setEventDetail(response.data);
      } else {
        setOpen(!response.status);
        setDisplayMessage(response.displayMessage);
      }
    });
  }, []);

  return (
    <Fragment>
      <Grid container flexDirection={"column"}>
        <Grid item container className="eventImageBox">
          <div
            style={{ width: "100%", position: "relative", overflow: "hidden" }}
          >
            <img
              style={{
                width: "100%",
                height: "35vh",
              }}
              src={eventDetail?.banner_image}
            />
          </div>
        </Grid>

        <Grid item padding={"20px"}>
          <Grid item container justifyContent={"center"}>
            <Grid item className="companyBanner" xs={6}>
              <img
                width="100%"
                height="100%"
                style={{ borderRadius: "12.5px" }}
                src={eventDetail?.banner_image}
              ></img>
            </Grid>

            <Grid item md={12} sl={6} sx={{ alignContent: "center" }}>
              <OuterBox>
                <OuterPaper elevation={3}>
                  {status === undefined ? (
                    <Fragment>
                      <Typography
                        variant="h3"
                        fontWeight={700}
                        textAlign={"center"}
                      >
                        Agreement to Consent Terms
                      </Typography>
                      <div>
                        <div>
                          <List>
                            <ListItem sx={{ padding: "10px" }}>
                              <ListItemText
                                primary={
                                  <Typography sx={{ fontSize: "15px" }}>
                                    We value your privacy and want to assure you
                                    that we do not store any of your data on our
                                    end. By using our services, you acknowledge
                                    and agree to this policy. We are committed
                                    to protecting your information and ensuring
                                    your privacy is maintained. Should you have
                                    any questions or concerns, please feel free
                                    to contact us. Thank you for trusting us
                                    with your privacy.
                                  </Typography>
                                }
                              />
                            </ListItem>
                            <ListItem sx={{ padding: "10px" }}>
                              <ListItemText
                                primary={
                                  <Typography sx={{ fontSize: "15px" }}>
                                    Your data is transferred to the e-gate pass
                                    24 hours in advance to ensure a secure and
                                    hassle-free check-in process. After
                                    check-in, your data will be deleted 24 hours
                                    later.
                                  </Typography>
                                }
                              />
                            </ListItem>

                            <ListItem sx={{ padding: "2px" }}>
                              <Checkbox
                                checked={checkFaceData}
                                onClick={() => setCheckFaceData(!checkFaceData)}
                              />
                              <ListItemText
                                primary={
                                  <Typography sx={{ fontWeight: "700" }}>
                                    I agree to all consent terms.
                                  </Typography>
                                }
                              />
                            </ListItem>

                            <ListItem sx={{ padding: "2px" }}>
                              <Checkbox
                                checked={checkContactDetails}
                                style={{ alignSelf: "baseline" }}
                                onClick={() =>
                                  setCheckContactDetails(!checkContactDetails)
                                }
                              />
                              <ListItemText
                                primary={
                                  <Typography sx={{ fontWeight: "700" }}>
                                    By checking this box, you give your consent
                                    for us to share your contact details with
                                    companies that have booths at the event you
                                    are attending. This will allow them to reach
                                    out to you with information about their
                                    products and services.
                                    <br />
                                    <br />I consent to share my contact details
                                    with event exhibitors.
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </List>
                        </div>
                      </div>

                      <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        // disabled={!checkFaceData}
                        size="small"
                      >
                        Submit
                      </LoadingButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item container justifyContent={"center"} mb={5}>
                        {status ? (
                          <CheckCircle
                            sx={{ color: "green", fontSize: "45px" }}
                          />
                        ) : (
                          <CancelOutlined
                            sx={{ color: "red", fontSize: "45px" }}
                          />
                        )}
                      </Grid>

                      <Typography
                        variant="h3"
                        fontWeight={700}
                        textAlign={"center"}
                        sx={{ color: status ? "green" : "red" }}
                      >
                        {status ? "Submit Successfully" : "Failed"}
                      </Typography>
                    </Fragment>
                  )}

                  <Error
                    open={open}
                    setOpen={setOpen}
                    ErrorMessage={displayMessage}
                    handleOnClick={() => window.location.assign(redirectURL)}
                    buttonContent={redirectURL ? "Verify Again" : ""}
                  />
                </OuterPaper>
              </OuterBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <EventBookingSuccess
        open={openSuccess}
        setOpen={setOpenSuccess}
        SuccessMessage={displayMessage}
      />
    </Fragment>
  );
}

export default Consent;
