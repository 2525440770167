import home from "../../pages/home components/Home";
import appointment from "../../pages/appointment components/Home";
import previewVideo from "../../pages/Liveliness/PreviewVideo";
import Liveliness from "../../pages/Liveliness/CaptureVideo";
import Success from "../../pages/Success/";
import Verification from "../../pages/Verification/Verificaion";
import UploadDocument from "../../pages/UploadDocument/UploadDocument";
import EventBooking from "../../pages/Booking/EventBooking/EventBooking";
import AppointmentBooking from "../../pages/Booking/AppointmentBooking/AppointmentBooking";
import EventBookingDetails from "../../pages/Booking/EventBooking/EventBookingDetails";
import AppointmentBookingDetails from "../../pages/Booking/AppointmentBooking/AppointmentBookingDetails";
import AadharverificaionDetails from "../../pages/Verification/Aadharverificaion/AadharverificaionDetails";
import DrivingLicenceDetails from "../../pages/Verification/DrivingLicence/DrivingLicenceDetails";
import BioVerificaion from "./../../pages/Booking/BioVerification/BioVerificaion";
import TrackAppointment from "./../../pages/Track Appointment/index.js";

import ContactUs from "../../pages/home components/Contact.js";
import AboutUs from "../../pages/home components/AboutUs.js";

import AadharverificaionSuccess from "../../pages/Verification/Aadharverificaion/AadharverificaionSuccess.js";

import ManualEntry from "../../pages/Manual Entry Components/ManualEntry.js";
import ResendNotification from "../../pages/Resend Notification Components/ResendNotification.js";
import Form from "../../pages/sign in components/Form.js";
import ManualVerification from "../../pages/Manual Verification Components/ManualVerification.js";
import MainGateEntryWithBackCamera from "../../pages/MainGate Entry With Back Camera  Components/MainGateEntry.js";
import MainGateEntryWithFrontCamera from "../../pages/MainGate Entry With Front Camera  Components/MainGateEntry.js";
import Consent from "../../pages/Consent components/Consent.js";
import EventList from "../../pages/Event Components/EventList.js";
import EventSummary from "../../pages/Event Summary Components/EventSummary.js";

const applicationLayoutRoutes = {
  eventListPageRoute: {
    ID: "EventList",
    path: "/",
    element: EventList,
  },

  appointmentPageRoute: {
    ID: "appointment",
    path: "/event/" + ":eventID",
    element: appointment,
  },

  ContactUs: {
    ID: "ContactUs",
    path: "/contact-us",
    element: ContactUs,
  },

  // AboutUs: {
  //   ID: "AboutUs",
  //   path: "/about-us",
  //   element: AboutUs,
  // },

  signInRoute: {
    ID: "sign-in",
    path: "/sign-in",
    element: Form,
  },

  TrackAppointmentPageRoute: {
    ID: "TrackAppointment",
    path: "/track-appointment",
    element: TrackAppointment,
  },

  previewVideoPageRoute: {
    ID: "previewVideo",
    path: "/event/previewVideo",
    element: previewVideo,
  },

  livelinessPageRoute: {
    ID: "liveliness",
    path: "/event/booking-verification",
    element: Liveliness,
  },

  Success: {
    ID: "Success",
    path: "/success",
    element: Success,
  },

  AadharverificaionSuccess: {
    ID: "AadharverificaionSuccess",
    path: "/aadhar-verificaion-success",
    element: AadharverificaionSuccess,
  },

  Verification: {
    ID: "Verification",
    path: "/event/verification",
    element: Verification,
  },

  UploadDocument: {
    ID: "UploadDocument",
    path: "/upload-document",
    element: UploadDocument,
  },
  EventBooking: {
    ID: "EventBooking",
    path: "/event/event-booking/" + ":eventID" + "/" + ":id",
    element: EventBooking,
  },

  EventBookingDetails: {
    ID: "EventBookingDetails",
    path: "/event/event-booking-details",
    element: EventBookingDetails,
  },

  // AppointmentBooking: {
  //   ID: "AppointmentBooking",
  //   path: "/appointment-booking",
  //   element: AppointmentBooking,
  // },

  // AppointmentBookingDetails: {
  //   ID: "AppointmentBookingDetails",
  //   path: "/appointment-booking-details",
  //   element: AppointmentBookingDetails,
  // },

  AadharverificaionDetails: {
    ID: "AadharverificaionDetails",
    path: "/aadhar-verificaion-details",
    element: AadharverificaionDetails,
  },

  DrivingLicenceDetails: {
    ID: "DrivingLicenceDetails",
    path: "/driving-licence-details",
    element: DrivingLicenceDetails,
  },
  BioVerificaion: {
    ID: "BioVerificaion",
    path: "/bio-verification",
    element: BioVerificaion,
  },

  MainGateEntryWithBackCamera: {
    ID: "MainGateEntryWithBackCamera",
    path: "/main-gate-entry-back",
    element: MainGateEntryWithBackCamera,
  },

  MainGateEntryWithFrontCamera: {
    ID: "MainGateEntryWithFrontCamera",
    path: "/main-gate-entry-front",
    element: MainGateEntryWithFrontCamera,
  },

  ManualEntry: {
    ID: "ManualEntry",
    path: "/manual-entry",
    element: ManualEntry,
  },

  ManualVerification: {
    ID: "ManualVerification",
    path: "/manual-verification",
    element: ManualVerification,
  },

  ResendNotification: {
    ID: "ResendNotification",
    path: "/resend-notification",
    element: ResendNotification,
  },

  Consent: {
    ID: "Consent",
    path: "/event/consent",
    element: Consent,
  },

  EventSummary: {
    ID: "EventSummary",
    path: "/event/event-summary/" + ":eventID" + "/" + ":id",
    element: EventSummary,
  },
};

export default applicationLayoutRoutes;
