import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import pages from "../../constants/pages";
import useAxios from "./../../hooks/useAxios";
import convertDateDayMonth from "../../utils/convertDateDayMonth";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

const EventList = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    axios({ url: "event/events/open-events", disableRedirect: true }).then(
      (res) => {
        res.status && setEventList(res.data);
      }
    );
  }, [axios]);

  const handleEvent = (id) => navigate(`${pages.appointment.route}${id}`);

  return (
    <Fragment>
      <OuterBox>
        <OuterPaper>
          <Typography
            textAlign="center"
            fontWeight={900}
            variant="h2"
            mb={3}
            mt={4}
            sx={{ "-webkit-text-stroke": "1px black" }}
          >
            Events List
          </Typography>
          <Grid container className="eventListOuterBox">
            {eventList.map(
              ({
                event_id,
                banner_image,
                event_name,
                event_start_date,
                ticket_cost,
              }) => (
                <Grid
                  key={event_id}
                  item
                  className="eventBox"
                  onClick={() => handleEvent(event_id)}
                  sx={{
                    background: `url(${
                      banner_image ||
                      "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1726724401857_pngtree-no-image-available-icon-flatvector-illustration-pic-design-profile-vector-png-image_40966566.jpg"
                    }) no-repeat center/cover`,
                    position: "relative",
                    width: { xs: "100%", sm: "45%" },
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="eventListImg"></div>

                  <Grid
                    container
                    position="absolute"
                    bottom={0}
                    sx={{ padding: "20px 5%", justifyContent: "space-between" }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        variant="h3"
                        fontWeight="bolder"
                        sx={{
                          fontSize: { xs: "1.25rem", md: "1.5rem" },
                          "-webkit-text-stroke-color": "black",
                          "-webkit-text-stroke-width": ".1px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {event_name}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        sx={{ color: "#3c3c3c", mt: 1 }}
                      >
                        {convertDateDayMonth(event_start_date)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        color: "white",
                        p: 1,
                        borderRadius: "12px",
                        background: " rgb(28 28 28/1)",
                        color: " white",
                        padding: " 10px",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{ fontSize: { xs: "1rem", md: "1.125rem" } }}
                      >
                        ₹ {ticket_cost}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{ fontSize: { xs: ".9rem", md: "1.125rem" } }}
                      >
                        Starting From
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </OuterPaper>
      </OuterBox>
    </Fragment>
  );
};

export default EventList;
